import { ElMessage } from 'element-plus'
/**
 * 函数防抖
 */
export function debounce(fn, delay = 300) {
  // 记录上一次的延时器
  let timer = null
  return function() {
    let args = arguments
    let that = this
    // 清除上一次延时器
    clearTimeout(timer)
    timer = setTimeout(function() {
      fn.apply(that,args)
    }, delay)
  }
}

/**
 * 函数节流
 */
export function throttle(fn, delay = 300){
  let lastTime
  // var timer
  return function() {
    let args = arguments
    // 记录当前函数触发的时间
    let nowTime = Date.now()
    if (lastTime && nowTime - lastTime < delay) {
      // clearTimeout(timer)
      // timer = setTimeout(function () {
      //   // 记录上一次函数触发的时间
      //   lastTime = nowTime
      //   // 修正this指向问题
      //   fn.apply(this, args)
      // }, delay)
    }else{
      lastTime = nowTime
      fn.apply(this, args)
    }
  }
} 
//复制
export function copy(data){
  if(data){
    // let oInput = document.createElement('input');
    // oInput.value = data;
    // document.body.appendChild(oInput);
    // oInput.select(); // 选择对象
    // document.execCommand('Copy'); // 执行浏览器复制命令
    // oInput.className = 'oInput';
    // oInput.style.display = 'none';
    // ElMessage({
    //   message: '复制成功',
    //   type: 'success',
    // })

    let input = document.createElement('textarea')
    input.style.opacity = '0'
    input.style.position = 'absolute'
    input.style.zIndex = '-1'
    document.body.append(input)
    input.value = data
    input.select()
    let result = document.execCommand('Copy')
    if (result) {
      ElMessage({
        message: '复制成功',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '复制失败',
        type: 'error',
      })
    }
    setTimeout(function () {
      document.body.removeChild(input)
    }, 50)
  }else{
    ElMessage({
      message: '没有要复制的内容',
      type: 'warning',
    })
  }
}