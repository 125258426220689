<template>
  <a-form ref="searchFormRef" layout="horizontal" :model="searchForm"
          class="m3-width-full m3-relative">
    <a-row gutter="20">
      <a-col span="4">
        <a-form-item label="状态">
          <div class="m3-flex m3-width-80">
            <a-select v-model:value="searchForm.status" :options="statusList"
                      placeholder="请选择" allowClear></a-select>
          </div>
        </a-form-item>
      </a-col>
      <a-col span="4">
        <a-form-item label="风险等级">
          <div class="m3-flex m3-width-80">
            <a-select v-model:value="searchForm.risk" :options="riskList"
                      placeholder="请选择" allowClear></a-select>
          </div>
        </a-form-item>
      </a-col>
      <a-col span="4">
        <a-form-item label="类型名称">
          <div class="m3-flex m3-width-80">
            <a-input v-model:value="searchForm.name" placeholder="请输入"/>
          </div>
        </a-form-item>
      </a-col>
      <a-col span="4">
        <a-form-item label="关键字串">
          <div class="m3-flex m3-width-80">
            <a-input v-model:value="searchForm.keywords" placeholder="请输入"/>
          </div>
        </a-form-item>
      </a-col>
      <a-col span="8">
        <div class="m3-flex m3-justify-content-between m3-padding-horizontal-s m3-width-80">
          <div class="m3-flex">
            <a-button :type="'primary'" @click="toSearch">
              查询
            </a-button>
            <a-button @click="resetSearch" class="m3-margin-left-s">
              重置
            </a-button>
          </div>
          <div class="m3-flex">
            <a-button v-if="!isAdminUser" :type="'primary'" @click="addKeyword" class="m3-margin-left-s">
              添加类型
            </a-button>
            <a-button :type="'primary'" @click="toSearch" class="m3-margin-left-s">
              刷新
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import {reactive, ref, toRaw} from "vue";
import {Form} from "ant-design-vue";

export default {
  name: "SearchForm",
  emits: ['change', 'search', 'addKeyword'],
  props: {
    isAdminUser: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {

    let searchFormRef = ref();
    let searchForm = reactive({
      status: '',
      risk: null,
      name: '',
      keywords: '',
    });

    let statusList = [
      {value: 0, label: '正常'},
      {value: 1, label: '禁用'},
    ]
    let riskList = [
      {
        value: null,
        label: '全部',
      },
      {
        value: 3,
        label: '高',
        labelClass: 'risk-high-more'
      },
      {
        value: 2,
        label: '中',
        labelClass: 'risk-high'
      },
      {
        value: 1,
        label: '低',
        labelClass: 'risk-mid'
      },
      {
        value: 0,
        label: '无',
        labelClass: 'risk-low'
      },
    ];
    //初始化后将参数发给列表页以初始化列表
    context.emit('change', toRaw(searchForm));

    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos, mergeValidateInfo} = useForm(searchForm, []);
    let toSearch = () => {
      context.emit('search', toRaw(searchForm));
    }
    let addKeyword = () => {
      context.emit('addKeyword');
    }
    let resetSearch = () => {
      resetFields();
      toSearch();
    }
    return {
      searchForm, searchFormRef,
      statusList, riskList,
      toSearch, resetSearch,addKeyword,
    }
  },
}
</script>

<style scoped>

</style>