<!-- // vos-用户设置 -->
<template>
  <div class="menu">
    <!-- 1.头部搜索查询 -->
    <el-row>
      <el-col :span="12" >
        <el-form label-position="right" label-width="100px" size="small" :model="form" ref="form" style="display: flex;">
        <el-form-item label="话术KEY">
          <el-input v-model="form.scriptKey" style="width: 300px;" placeholder="请输入话术KEY" 
            size="mini"></el-input>
        </el-form-item>
        <el-form-item label="关键词">
          <el-input v-model="form.keyword"   style="width: 300px;" placeholder="请输入关键词" size="mini">
          </el-input>
        </el-form-item>
        <el-form-item>
        </el-form-item>
      </el-form>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <div class="ml-10">
            <el-button icon="el-icon-plus" class="button-el" size="medium" @click="addDialogVisible=true">添加
            </el-button>
          </div>
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="handleSearch">查询
            </el-button>
          </div>
          <div class="ml-10">
            <div class="font-refresh-out flex-nowrap-center-center" @click="searchReset">
              <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
              <i v-else class="el-icon-loading loding-color"></i>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 2.主体列表 -->
    <el-row>
      <el-col :span="24">
        <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" size="mini"
          :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" default-expand-all
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column label="话术名">
            <template #default="scope">{{ scope.row.scriptName == null?'-':scope.row.scriptName}}</template>
          </el-table-column>
          <el-table-column label="话术KEY">
            <template #default="scope">{{ scope.row.scriptKey == null?'-':scope.row.scriptKey}}</template>
          </el-table-column>
          <el-table-column label="话术关键词">
            <template #default="scope">{{ scope.row.scriptKeyword == null?'-':scope.row.scriptKeyword}}</template>
          </el-table-column>
          <el-table-column label="话术内容">
            <template #default="scope">{{ scope.row.script == null?'-':scope.row.script}}</template>
          </el-table-column>
          <el-table-column label="创建日期">
            <template
              #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime}}</template>
          </el-table-column>
          <el-table-column label="更新日期">
            <template
              #default="scope">{{ scope.row.updateTime == null|| scope.row.updateTime == ''?'-': scope.row.updateTime}}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-tooltip content="删除" placement="top" effect="light">
                <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="handleDelete(scope.row)"
                  circle>
                </el-button>
              </el-tooltip>
              <el-tooltip content="修改" placement="top" effect="light">
                <el-button type="primary" plain size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)" circle>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 3.分页器 -->
    <div class="pager">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="form.page" background :page-sizes="[10, 50, 100, 200]" :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 新增用户模态框 -->
    <el-dialog title="新增" v-model="addDialogVisible" :close-on-click-modal="false" :destroy-on-close="true"
      width="600px">
      <el-form :rules="rules1" label-position="right" label-width="100px" size="small" :model="addFrom" ref="addform">
        <el-form-item label="话术名称" prop="scriptName">
          <el-input v-model="addFrom.scriptName" class="input-searchFrom-width" placeholder="请输入话术名称" clearable
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="话术KEY" prop="scriptKey">
          <el-input v-model="addFrom.scriptKey" class="input-searchFrom-width" placeholder="请输入话术KEY" clearable
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="话术" prop="script">
          <el-input v-model="addFrom.script" class="input-searchFrom-width" placeholder="请输入话术" clearable
          type="textarea" :rows="4"
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="话术关键词"  >
          <el-input has-feedback v-model="inputText" class="input-searchFrom-width" placeholder="请输入敏感词后回车" clearable
          @keyup.enter="addToResult"
            size="mini">
          </el-input>
        </el-form-item>
        <a-form-item label="关键词设置规则：">
            <div>
              <p>1、单个关键词输入后，按回车键保存（装修）+ 回车键 </p>
              <p>2、多个关键词请使用英文状态下“,”分割（贷款,利率,装修）+ 回车键</p>
              <p>3、组合关键词请使用“|”分割（贷款|利率|装修,教育|培训|幼儿）</p>
            </div>
            <div style="height: 200px;overflow: auto;border: 1px solid #e4e4e4;padding: 10px">
              <a-tag style="margin-bottom: 10px"  closable @close="handleClose(item)"
              v-for="(item, index) in results" :key="item" >{{ item }}
              </a-tag>
              <a-tag  
                     style="margin-bottom: 10px;margin-right: 10px;"
                     color="processing"
                     @click="copyKeywords"
                     key="copy">复制
              </a-tag>
            </div>
          </a-form-item>
          <!-- <el-form-item label="话术关键词" prop="scriptKeyword">
            <el-input has-feedback  class="input-searchFrom-width"  :value="resultText" clearable type="textarea" :rows="4" disabled
            size="mini">
          </el-input>
        </el-form-item> -->
        <el-form-item>
          <div class="flex-nowrap-flex-end">
            <el-button class="cancel" plain @click="handleCancle('addform')" round>
              取消
            </el-button>
            <el-button class="confirmAdd" @click="handleSubmit('addform')" round>
              确认
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查询用户模态框 -->
    <!-- <el-dialog title="查询" v-model="searchDialogVisible" :close-on-click-modal="false" :destroy-on-close="true"
      width="600px">
      <el-form label-position="right" label-width="100px" size="small" :model="searchFrom" ref="searchFrom">
        <el-form-item label="话术KEY">
          <el-input v-model="form.scriptKey" class="input-searchFrom-width" placeholder="请输入话术KEY"
            size="mini"></el-input>
        </el-form-item>
        <el-form-item label="关键词">
          <el-input v-model="form.keyword" class="input-searchFrom-width" placeholder="请输入关键词" size="mini">
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-nowrap-flex-end">
            <el-button class="cancel" plain @click="searchReset" round>
              重置
            </el-button>
            <el-button class="confirmAdd" @click="handleSearch" round>
              查询
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog> -->
    <!-- 编辑用户模态框 -->
    <el-dialog title="编辑" v-model="editDialogVisible" :close-on-click-modal="false" :destroy-on-close="true"
      width="600px">
      <el-form :rules="rules" label-position="right" label-width="100px" size="small" :model="editFrom" ref="editFrom">
        <!-- <el-form-item label="话术关键词" prop="scriptKeyword">
          <el-input v-model="editFrom.scriptKeyword" class="input-searchFrom-width" placeholder="请输入话术关键词" clearable
          type="textarea" :rows="8"
            size="mini">
          </el-input>
        </el-form-item> -->
        <el-form-item label="话术名称" prop="scriptName">
          <el-input v-model="editFrom.scriptName" class="input-searchFrom-width" placeholder="请输入话术名称" clearable
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="话术KEY" prop="scriptKey">
          <el-input v-model="editFrom.scriptKey" class="input-searchFrom-width" placeholder="请输入话术KEY" clearable
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="话术" prop="script">
          <el-input v-model="editFrom.script" class="input-searchFrom-width" placeholder="请输入话术" clearable
          type="textarea" :rows="4"
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="话术关键词"  >
          <el-input has-feedback v-model="inputText1" class="input-searchFrom-width" placeholder="请输入敏感词后回车" clearable
          @keyup.enter="addToResult1"
            size="mini">
          </el-input>
        </el-form-item>
        <a-form-item label="关键词设置规则：">
            <div>
              <p>1、单个关键词输入后，按回车键保存（装修）+ 回车键 </p>
              <p>2、多个关键词请使用英文状态下“,”分割（贷款,利率,装修）+ 回车键</p>
              <p>3、组合关键词请使用“|”分割（贷款|利率|装修,教育|培训|幼儿）</p>
            </div>
            <div style="height: 200px;overflow: auto;border: 1px solid #e4e4e4;padding: 10px">
              <a-tag style="margin-bottom: 10px"  closable @close="handleClose1(item)"
              v-for="(item, index) in results1" :key="item" >{{ item }}
              </a-tag>
              <a-tag  
                     style="margin-bottom: 10px;margin-right: 10px;"
                     color="processing"
                     @click="copyKeywords"
                     key="copy">复制
              </a-tag>
            </div>
          </a-form-item>
        <el-form-item>
          <div class="flex-nowrap-flex-end">
            <el-button class="cancel" plain @click="handleEditCancle('editFrom')" round>
              取消
            </el-button>
            <el-button class="confirmAdd" @click="handleEditSubmit('editFrom')" round>
              确认
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store/index'
import { defaultTime } from '@/utils/timeToChoose.js'
import { IconFont } from '@/utils/iconfont.js'
import userts from '@/api/web/user'
import { ElMessageBox } from 'element-plus'
import { h } from 'vue'
import SearchForm from '@/views/open/apiQuality/keyword/SearchForm.vue';
import{scriptMatchingView} from '@/api/open/amount/amount';
import{scriptAddition} from '@/api/open/amount/amount';
import{scriptEditor} from '@/api/open/amount/amount';
import{scriptDeletion} from '@/api/open/amount/amount';
import{scriptQuery} from '@/api/open/amount/amount';
import {copy} from "@/utils/common"
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      userType: store.state.userType,
      shortcuts: [
        {
          text: '上周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '上月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '前三月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
      addDialogVisible: false, //新增用户模态框
      //新增参数
      addFrom: {
        scriptName:'',
        scriptKey:'',
        script:'',
        scriptKeyword:''
      },
      rules1: {
        scriptName: [
          {
            required: true,
            message: '请输入话术名称',
            trigger: 'blur',
          },
        ],
        scriptKey: [
          {
            required: true,
            message: '请输入话术KEY',
            trigger: 'blur',
          },
        ],
      },
      inputText: '',
      results: [], // 用数组存储每次输入的内容
      results1: [], // 用数组存储每次输入的内容
      valueTime: '', //选中时间段
      searchDialogVisible: false, //查询用户模态框
      //查看
      form:{
        scriptKey: "",
        keyword:"",
        page:1,
        pageSize:10,
      },
      //查询参数
      searchFrom: {
        page: 1,
        pageSize: 10,
        scriptKey: "",
        keyword:"",
      },
      total: 0,
      editDialogVisible: false, //编辑用户模态框
      //编辑参数
      editFrom: {
        uid:'',
        scriptName:'',
        scriptKey:'',
        script:'',
        scriptKeyword:''
      },
      inputText1:'',
      tableData: [], //菜单表
      loading: true,
      rules: {
        scriptName: [
          {
            required: true,
            message: '请输入话术名称',
            trigger: 'blur',
          },
        ],
        scriptKey: [
          {
            required: true,
            message: '请输入话术KEY',
            trigger: 'blur',
          },
        ],
      },
      currUser: null
    }
  },
  computed: {
    resultText() {
      return this.results.join(','); // 将数组元素以空格连接起来形成结果文本
    }
  },
  mounted() {
    this.getDataList(true)
  },
  created() {
    this.currUser = this.$store.getters.user
  },
  methods: {
    //新增用户---->取消
    handleCancle(name) {
      this.addFrom = {
                scriptName:'',
                scriptKey:'',
                script:'',
                scriptKeyword:''
      }
     this.inputText= ''
      this.$refs[name].resetFields()
      this.addDialogVisible = false
    },
   
    //新增用户---->确认
    handleSubmit(name) {
this.addFrom.scriptKeyword=this.results.join(",")
      this.$refs[name].validate((valid) => {
        if (!valid) {
          return 
        } else {
          scriptAddition({ ...this.addFrom }).then((res) => {
            if (res.code !== 200) {
              return this.$message.error(res.message)
            }else{
              this.addFrom = {
                scriptName:'',
                scriptKey:'',
                script:'',
                scriptKeyword:''
              }
              this.results=[]
              this.$refs[name].resetFields()
              this.addDialogVisible = false
              this.getDataList(true)
              this.$message.success('添加成功')
            }
          })
        }
      })
    },
    addToResult() {
      if (this.inputText=='') {
        this.$message.info('输入框不可为空')
      }else{
        if (this.inputText.trim() !== '') {
    const keywords = this.inputText.trim().split(',');
    keywords.forEach(keyword => {
      if (keyword.trim() !== '') {
        this.results.push(keyword.trim());
      }
    });
    this.inputText = '';
  }
      }

},
    addToResult1() {
      if (this.inputText1=='') {
        this.$message.info('输入框不可为空')
      }else{
        if (this.inputText1.trim() !== '') {
    const keywords1 = this.inputText1.trim().split(',');
    keywords1.forEach(keyword => {
      if (keyword.trim() !== '') {
        this.results1.push(keyword.trim());
      }
    });
    this.inputText = '';
  }
      }
    },
    //复制
    copyKeywords() {
      if (this.results.length > 0) {
        let copyText = this.results.join(',');
        copy(copyText);
      }
    },
    // 处理关闭标签的操作
    handleClose(item) {
      const index = this.results.indexOf(item);
      if (index !== -1) {
        this.results.splice(index, 1);
      }
    },
     // 编辑处理关闭标签的操作
     handleClose1(item) {
      const index = this.results1.indexOf(item);
      if (index !== -1) {
        this.results1.splice(index, 1);
      }
    },
    // 查询
    handleSearch() {
      this.searchDialogVisible = false
      this.getDataList()
    },
    // 查询重置
    searchReset() {
      this.searchFrom = {
        page: 1,
        pageSize: 10,
        scriptKey: "",
        keyword:"",
      }
      this.getDataList()
    },
  
    //删除用户
    handleDelete(row) {
      ElMessageBox({
        title: '提示消息',
        message: h('p', null, [
          h('span', null, '请再次确认是否删除 '),
        ]),
        showCancelButton: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      })
        .then(() => {
          scriptDeletion({ id: row.id }).then((res) => {
            if (res.code !== 200) {
              return this.$message.error(res.message)
            } else {
              this.$message.success('删除成功')
              this.getDataList(true)
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    //编辑用户
    handleEdit(row) {
      console.log('row :>> ', row);
      this.editFrom.scriptKeyword = row.scriptKeyword
      this.results1= row.scriptKeyword.split(",")
      this.editFrom.scriptName = row.scriptName
      this.editFrom.scriptKey = row.scriptKey
      this.editFrom.script = row.script
      this.editFrom.id = row.id
      this.editFrom.uid = row.uid
      this.editDialogVisible = true
    },
    //编辑用户---->取消
    handleEditCancle(name) {
      this.$refs[name].resetFields()
      this.editDialogVisible = false
    },
    //编辑用户---->确认
    handleEditSubmit(name) {
      this.editFrom.scriptKeyword=this.results1.join(",")
      this.$refs[name].validate((valid) => {
        if (!valid) {
          return
        } else {
          scriptEditor({ ...this.editFrom }).then((res) => {
            
            if (res.code !== 200) {
              return this.$message.error(res.message)
            } else {
              this.$refs[name].resetFields()
              this.editDialogVisible = false
              this.getDataList(true)
              this.$message.success('编辑成功')
            }
          })
        }
      })
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type
      scriptMatchingView({
        ...this.form,
      }).then((res) => {
        console.log(res,'2222222222222');
        if (res.code !== 200) {
          return this.$message.success(res.message)
        } else {
          this.tableData = res.data.records
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.form.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getDataList(true)
    },
  },
}

</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 1.125rem;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.pass {
  color: #67c23a;
}
.unpass {
  color: #f56c6c;
}
.priceColor {
  color: #409eff;
}
.talks {
  cursor: pointer;
}
.record {
  height: 5rem;
}
.dialogue {
  overflow-y: overlay;
  height: 25rem;
  padding: 0.425rem 1.5rem;
  background: #f5f5f5;
}
.recordSon {
  width: 100%;
}
.item-text {
  display: inline-block;
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: #000;
}
.item-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.item-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.title-left {
  text-align: left;
}
.title-right {
  text-align: right;
}
.title-item {
  padding: 0.375rem;
}
.talkcolor {
  font-size: 1rem;
  color: #fff;
}
.Read {
  background: #abadbb;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.eltable {
  overflow-y: overlay;
}

</style>